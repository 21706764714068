.notificationHolder {
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 25vh;
}

.notification-input {
  margin-right: 20vw;
  margin-left: -1vw;
}

.magnitudeNote {
  margin-right: 10vw;
  margin-left: -1vw;
}
