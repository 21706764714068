.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}

.eqgoogle-map {
  width: 50vw;
  height: 97.5%;
}

.pin {
  display: flex;
  align-items: center;
  width: 80px;
}

.pin--lightgreen {
  color: #03fc17;
}

.pin--yellow {
  color: #f8fc03;
}

.pin--orange {
  color: #ff9900;
}

.pin--red {
  color: #ff1c1c;
}

.pin-icon {
  font-size: 1rem;
}

.pin-text {
  font-size: 1.3em;
}

@media screen and (min-width: 799px) {
  .map-h2 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .pin {
    width: 5vw;
  }

  .pin-icon {
    font-size: 2vw;
  }
}
