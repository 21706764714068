body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("https://thumbs.gfycat.com/RingedSpicyKodiakbear-size_restricted.gif");
}
body::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: black; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%);
  border-radius: 20px; /* roundness of the scroll thumb */

  box-shadow: inset 0px 0px 0px 1px black;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.overflow-controller {
  overflow: hidden;
}

.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.splash {
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  color: white;
}

.splashlogo {
  margin-top: -2rem;
  margin-bottom: -7rem;
}

.engage {
  cursor: pointer;
  pointer-events: auto;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 0.5rem;
}

.splashDesc {
  display: inline;
}

.engage:hover {
  background-color: #fd5f00;
}

.hidden {
  opacity: 0;
}

@-webkit-keyframes fade-in {
  0% {
    -webkit-filter: blur(10px);
            filter: blur(10px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    -webkit-filter: blur(10px);
            filter: blur(10px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.tippy-content {
    white-space: pre-wrap;
}

.globe {
  height: 100vh;
}

.cover {
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  background-color: black;
}

.unstarted {
  pointer-events: none;
}

.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}

.google-map {
  width: 100%;
  height: 100vh;
}

.pin {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 80px;
  cursor: pointer;
}

.pin--lightgreen {
  color: #03fc17;
}

.pin--yellow {
  color: #f8fc03;
}

.pin--orange {
  color: #ff9900;
}

.pin--red {
  color: #ff1c1c;
}

.pin-icon {
  font-size: 1rem;
}

.pin-text {
  font-size: 1.3em;
}

@media screen and (min-width: 799px) {
  .google-map {
    height: 100vh;
  }

  .map-h2 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .pin {
    width: 5vw;
  }

  .pin-icon {
    font-size: 2vw;
  }
}

.navbarApp {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.mainGroup {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.rightNavGroup {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

#welcome {
  cursor: default;
  color: white;
}

.quakeTitle {
  color: white;
}

.quakepageController {
  padding-top: 5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.eqInfo {
  margin-top: 3rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  color: white;
  font-weight: bold;
  font-family: "Oxygen";
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
ul.noPaddingTop,
li.noPaddingTop {
  padding-top: 0;
}

.quakeInfo {
  border-bottom: 1px solid white;
}

.backToHome {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
}

.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}

.eqgoogle-map {
  width: 50vw;
  height: 97.5%;
}

.pin {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 80px;
}

.pin--lightgreen {
  color: #03fc17;
}

.pin--yellow {
  color: #f8fc03;
}

.pin--orange {
  color: #ff9900;
}

.pin--red {
  color: #ff1c1c;
}

.pin-icon {
  font-size: 1rem;
}

.pin-text {
  font-size: 1.3em;
}

@media screen and (min-width: 799px) {
  .map-h2 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .pin {
    width: 5vw;
  }

  .pin-icon {
    font-size: 2vw;
  }
}

.Chatbox {
  width: 50%;
  max-width: 400px;
  margin: 0 auto;
}

.post {
  text-align: center;
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

input,
textarea {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #dedede;
  padding: 10px;
}

button {
  display: inline-block;
  height: 38px;
  padding: 0 px;
  color: white;
  text-align: center;
  font-size: 11px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 2px;
  background-color: transparent;
  border: 1px solid white;
  cursor: pointer;
  box-sizing: border-box;
}

.comment {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.voting {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-content: center;
          align-content: center;
}

.upvote {
  background-color: #073525;
  border: 1px solid #073525;
  margin-right: 10px;
}

.downvote {
  background-color: #ff0026;
  border: 1px solid #ff0026;
}

.commentController {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.inputLogo {
  max-height: 2rem;
  max-width: 2rem;
}

.singleComment {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  background-color: rgba(32, 30, 30, 0.8);
  box-shadow: 0 0 8px rgba(102, 98, 98, 0.2);
  width: 40vw;
  padding: 1rem;
  border-radius: 20px;
  margin-bottom: 1rem;
}

.comment-username {
  position: relative;
  width: 100%;
  color: white;
  font-size: 1.2rem;
  padding-bottom: 1rem;
  text-decoration: underline;
  text-align: left;
}

.comment-content {
  position: relative;
  width: 100%;
  color: lightgrey;
  font-size: 0.9rem;
  text-align: left;
}

:root {
  --black: #333;
  --white: rgb(32, 30, 30);
  --gray: #aaa;
  --green: #fd5f00;
  --border-radius: 20px;
  --transition: 0.4s ease;
  --font-family: Arial, Helvetica, sans-serif;
  --shadow: silver;
  --capacity: 50%;
}

/* * {
  box-sizing: border-box;
} */

body {
  font-family: Arial, Helvetica, sans-serif;
  font-family: var(--font-family);
}

/* textarea {
  font-family: var(--font-family);
  border: none;
  resize: none;
} */

textarea::-webkit-input-placeholder {
  color: #aaa;
  color: var(--gray);
}

textarea::placeholder {
  color: #aaa;
  color: var(--gray);
}

/* label {
  color: white;
  text-align: center;
} */

button {
  cursor: pointer;
}

button:disabled {
  opacity: 0.5;
  background-color: transparent;
  color: white;
  cursor: not-allowed;
}

button[type="submit"]:disabled {
  background: white;
  color: rgb(32, 30, 30);
  color: var(--white);
  border-radius: 20px;
  border-radius: var(--border-radius);
}

button[type="submit"] {
  background: #fd5f00;
  background: var(--green);
  color: white;
  border-radius: 20px;
  border-radius: var(--border-radius);
}

.comment-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 50vw;
  margin: 50px auto;
  background: rgb(32, 30, 30);
  background: var(--white);
  border-radius: 20px;
  border-radius: var(--border-radius);
  box-shadow: 0 0 8px rgba(102, 98, 98, 0.2);
  overflow: hidden;
  padding: 14px;
  transition: min-height 0.4s ease;
  transition: min-height var(--transition);
  max-height: 46px;
  visibility: 50px;
}

.headerInput {
  position: relative;
  width: 100%;
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: 3rem;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.user {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.user img {
  border-radius: 10px;
  margin-right: 5px;
}

.comment-field {
  color: lightgrey;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  transition: -webkit-transform var(--transition);
  transition: transform var(--transition);
  transition: transform var(--transition), -webkit-transform var(--transition);
  font-size: 18px;
  width: 100%;
  outline: none;
  padding: 0.2rem;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  min-height: 60px;
  -webkit-transform: translateY(-32px);
          transform: translateY(-32px);
  line-height: 1;
  width: 100%;
  /* border: 1px solid #dedede; */
  border: none;
  border-radius: 15px;
  font-family: Arial, Helvetica, sans-serif;
  font-family: var(--font-family);
  resize: none;
  visibility: none;
  transition: opacity 0.4s ease 0.2s;
  transition: opacity var(--transition) 0.2s;
  opacity: 0;
  background-color: #363232;
}

.comment-box.expanded {
  max-height: none;
}

.comment-box.expanded .comment-field {
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
  visibility: visible;
  transition: opacity 0.4s ease 0.2s;
  transition: opacity var(--transition) 0.2s;
  opacity: 1;
}

.comment-box.expanded .headerInput {
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.actions {
  padding-top: 2rem;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: flex-end;
          align-self: flex-end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  opacity: 0;
  visibility: none;
  transition: opacity 0.4s ease 0.2s;
  transition: opacity var(--transition) 0.2s;
}

.comment-box.expanded .actions {
  opacity: 1;
  transition: opacity 0.4s ease 0.2s;
  transition: opacity var(--transition) 0.2s;
  visibility: visible;
}

.actions button {
  margin-left: 6px;
  font-size: 14px;
  padding: 12px;
  border: none;
  line-height: 1;
}

.actions .cancel {
  background: none;
}


.inputPrompt {
  color: white;
  text-align: center;
  margin-top: 5px;
  margin-right: 2rem;
}

.notificationHolder {
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 25vh;
}

.notification-input {
  margin-right: 20vw;
  margin-left: -1vw;
}

.magnitudeNote {
  margin-right: 10vw;
  margin-left: -1vw;
}

/* Color Variables */

:root {
  --text: rgba(0, 0, 0, 0.349);
  --btnText: #f2f2f2;
  --btnBackground: #a1a2ac;
  --active: #d9bb29;
  --inactive: #cbcdd8;
  --background: #f2f2f2;
  --secondary: #f2f2f2;
}

/* TOGGLE BUTTON */
.toggle-container {
  position: fixed;
  top: 10%;
  right: 10%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.toggle-btn {
  display: container;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border: none;
  border-radius: 100px;
  box-shadow: 2 0px 0px 2 rgba(245, 241, 241, 0.2);
}

.icon {
  font-size: 2.2rem;
  padding: 0rem 0rem;
  color: #cbcdd8;
  color: var(--inactive);
}

.toggle-btn:hover {
  box-shadow: 1 0px 0px 1 rgb(245, 239, 239);
}

.active {
  color: #d99b29;
}

@media (max-width: 400px) {
  .toggle-container {
    top: 0%;
    right: 50%;
  }
}

