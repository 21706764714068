.Chatbox {
  width: 50%;
  max-width: 400px;
  margin: 0 auto;
}

.post {
  text-align: center;
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

input,
textarea {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #dedede;
  padding: 10px;
}

button {
  display: inline-block;
  height: 38px;
  padding: 0 px;
  color: white;
  text-align: center;
  font-size: 11px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 2px;
  background-color: transparent;
  border: 1px solid white;
  cursor: pointer;
  box-sizing: border-box;
}

.comment {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.voting {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.upvote {
  background-color: #073525;
  border: 1px solid #073525;
  margin-right: 10px;
}

.downvote {
  background-color: #ff0026;
  border: 1px solid #ff0026;
}

.commentController {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inputLogo {
  max-height: 2rem;
  max-width: 2rem;
}

.singleComment {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(32, 30, 30, 0.8);
  box-shadow: 0 0 8px rgba(102, 98, 98, 0.2);
  width: 40vw;
  padding: 1rem;
  border-radius: 20px;
  margin-bottom: 1rem;
}

.comment-username {
  position: relative;
  width: 100%;
  color: white;
  font-size: 1.2rem;
  padding-bottom: 1rem;
  text-decoration: underline;
  text-align: left;
}

.comment-content {
  position: relative;
  width: 100%;
  color: lightgrey;
  font-size: 0.9rem;
  text-align: left;
}
