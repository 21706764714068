:root {
  --black: #333;
  --white: rgb(32, 30, 30);
  --gray: #aaa;
  --green: #fd5f00;
  --border-radius: 20px;
  --transition: 0.4s ease;
  --font-family: Arial, Helvetica, sans-serif;
  --shadow: silver;
  --capacity: 50%;
}

/* * {
  box-sizing: border-box;
} */

body {
  font-family: var(--font-family);
}

/* textarea {
  font-family: var(--font-family);
  border: none;
  resize: none;
} */

textarea::placeholder {
  color: var(--gray);
}

/* label {
  color: white;
  text-align: center;
} */

button {
  cursor: pointer;
}

button:disabled {
  opacity: 0.5;
  background-color: transparent;
  color: white;
  cursor: not-allowed;
}

button[type="submit"]:disabled {
  background: white;
  color: var(--white);
  border-radius: var(--border-radius);
}

button[type="submit"] {
  background: var(--green);
  color: white;
  border-radius: var(--border-radius);
}

.comment-box {
  display: flex;
  flex-wrap: wrap;
  width: 50vw;
  margin: 50px auto;
  background: var(--white);
  border-radius: var(--border-radius);
  box-shadow: 0 0 8px rgba(102, 98, 98, 0.2);
  overflow: hidden;
  padding: 14px;
  transition: min-height var(--transition);
  max-height: 46px;
  visibility: 50px;
}

.headerInput {
  position: relative;
  width: 100%;
  transform: translateY(-40px);
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
  justify-content: space-between;
}

.user {
  display: flex;
  align-items: center;
}

.user img {
  border-radius: 10px;
  margin-right: 5px;
}

.comment-field {
  color: lightgrey;
  transition: transform var(--transition);
  font-size: 18px;
  width: 100%;
  outline: none;
  padding: 0.2rem;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  min-height: 60px;
  transform: translateY(-32px);
  line-height: 1;
  width: 100%;
  /* border: 1px solid #dedede; */
  border: none;
  border-radius: 15px;
  font-family: var(--font-family);
  resize: none;
  visibility: none;
  transition: opacity var(--transition) 0.2s;
  opacity: 0;
  background-color: #363232;
}

.comment-box.expanded {
  max-height: none;
}

.comment-box.expanded .comment-field {
  transform: translateY(40px);
  visibility: visible;
  transition: opacity var(--transition) 0.2s;
  opacity: 1;
}

.comment-box.expanded .headerInput {
  transform: translateY(10px);
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.actions {
  padding-top: 2rem;
  width: 100%;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  opacity: 0;
  visibility: none;
  transition: opacity var(--transition) 0.2s;
}

.comment-box.expanded .actions {
  opacity: 1;
  transition: opacity var(--transition) 0.2s;
  visibility: visible;
}

.actions button {
  margin-left: 6px;
  font-size: 14px;
  padding: 12px;
  border: none;
  line-height: 1;
}

.actions .cancel {
  background: none;
}


.inputPrompt {
  color: white;
  text-align: center;
  margin-top: 5px;
  margin-right: 2rem;
}
