.tippy-content {
    white-space: pre-wrap;
}

.globe {
  height: 100vh;
}

.cover {
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  background-color: black;
}

.unstarted {
  pointer-events: none;
}
